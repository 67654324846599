.Error_Closure {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  margin: 1rem;
  max-width: 600px;
}
.Error_Closure .Error_Closure--header {
  display: inline-flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #eee;
  width: 100%;
  background: #f9f9f9;
}
.Error_Closure .Error_Closure--header span {
  width: 30px;
  height: 30px;
  background: #e13505;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
}
.Error_Closure .Error_Closure--header h2 {
  font-size: 1rem;
  color: #e13505;
  padding-left: 1.5rem;
  margin: 0;
  font-weight: 500;
}
.Error_Closure .Error_Closure--body {
  padding: 2rem 1rem;
}
.Error_Closure .Error_Closure--body h3 {
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
  font-size: 1rem;
  color: #424242;
}
.Error_Closure .Error_Closure--footer {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eee;
}
.Error_Closure .Error_Closure--footer button {
  border: none;
  background: #000;
  color: #fff;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  border-radius: 6px;
  padding: 0.75rem 1.75rem;
}
.Error_Closure .Error_Closure--footer button:hover {
  cursor: pointer;
}
