.Register {
  margin-top: 3rem;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 150px;
  min-height: 100vh;
}
.Register .Register__helper {
  display: inline-flex;
  width: auto;
  flex-flow: column;
  flex-wrap: wrap;
  background: linear-gradient(0deg, #fff, #f4f8fc);
  padding: 2rem;
  padding-left: calc((100% - 1140px) / 2);
  margin-right: 5%;
}
.Register .Register__helper .Register--titles {
  width: 100%;
  text-align: right;
}
.Register .Register__helper .Register--titles .Register--titles--inner {
  display: inline-block;
}
.Register .Register__helper .Register--titles .Register--titles--inner h1 {
  width: 100%;
  font-size: 3rem;
  margin-bottom: 0;
  white-space: nowrap;
}
.Register .Register__helper .Register--titles .Register--titles--inner p {
  margin-top: 0;
  font-size: 1.1rem;
  text-align: right;
  width: 60%;
  display: inline-block;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step {
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 2rem;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one,
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-two,
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-three {
  font-family: "Inter", sans-serif;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  font-weight: 600;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one {
  background: #0f1540;
  color: #fff;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:before,
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:after {
  content: "";
  position: absolute;
  height: 5px;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:before {
  top: 45%;
  left: -80%;
  width: 25px;
  background: #0f1540;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:after {
  left: 100%;
  width: 160px;
  background: #a8a8a8;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-two {
  color: #a8a8a8;
  background: #fff;
  border: 3px solid #a8a8a8;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-two:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 40%;
  background: #a8a8a8;
  height: 5px;
  width: 100px;
}
.Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-three {
  color: #a8a8a8;
  background: #fff;
  border: 3px solid #a8a8a8;
}
.Register .Register__helper .Register__steps {
  margin-top: 2rem;
  margin-left: auto;
}
.Register .Register__helper .Register__steps .Register--step {
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  margin: 2rem 0;
  padding: 1rem;
  color: #a8a8a8;
}
.Register .Register__helper .Register__steps .Register--step .Register--step--inner {
  display: inline-block;
}
.Register .Register__helper .Register__steps .Register--step h2 {
  margin: 0 0 0.5rem 0;
}
.Register .Register__helper .Register__steps .Register--step p {
  margin: 0;
}
.Register .Register__helper .Register__steps .Register--step.active {
  box-shadow: #97b4cb32 0 3px 20px;
  background: #fff;
  border-radius: 6px;
}
.Register .Register__helper .Register__steps .Register--step.active h2 {
  color: #0f1540;
}
.Register .Register__helper .Register__steps .Register--step.active p {
  color: #0f1540;
}
.Register .Register__helper .Register__steps .Register--step.active:after {
  content: "";
  display: inline-block;
  background: url("../../images/arrow.svg") no-repeat;
  width: 30px;
  height: 15px;
  margin-left: 15px;
}
.Register .Register__container {
  display: inline-flex;
  width: 40%;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner {
  width: 469px;
  max-width: 80%;
}
.Register .Register__container .Register--inner h3 {
  font-size: 1.5rem;
  color: #262a68;
  border-bottom: solid 1px #dedede;
  padding-bottom: 0.5rem;
  width: 100%;
}
.Register .Register__container .Register--inner h4 {
  color: #262a68;
  font-size: 1.25rem;
  margin-top: 3rem;
  width: 100%;
  margin-bottom: 0.75rem;
}
.Register .Register__container .Register--inner form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Register .Register__container .Register--inner form .Fleet__cont {
  transition: all 0.5s linear;
  max-height: 500px;
}
.Register .Register__container .Register--inner form .hidden {
  max-height: 0;
  overflow: hidden;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst {
  display: none;
  align-items: center;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst.active {
  display: flex;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block {
  display: inline-flex;
  width: 100%;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline:first-child {
  margin-right: 0.5rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label {
  position: relative;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label input:focus ~ label,
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.3rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label label {
  top: 1.25rem;
}
.Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .error-message {
  top: -10px;
}
.Register .Register__container .Register--inner form .dropdown-wrapper {
  margin-top: 0.75rem;
  width: 100%;
  position: relative;
}
.Register .Register__container .Register--inner form .dropdown {
  width: 100%;
  margin-top: 0.75rem;
}
.Register .Register__container .Register--inner form .dropdown.true {
  border: 1px solid #d40000;
}
.Register .Register__container .Register--inner form .dropdown.invalid .react-select__control {
  border-color: #d40000;
}
.Register .Register__container .Register--inner form .dropdown > div {
  border-radius: 0;
  padding: 0.6rem 0;
  border: 1px solid #ececec;
}
.Register .Register__container .Register--inner form .floating-label {
  width: 100%;
  position: relative;
}
.Register .Register__container .Register--inner form .floating-label input {
  display: flex;
  width: 100%;
  padding: 1rem;
  padding-top: 1.35rem;
  font-family: "Inter", sans-serif;
  background: #fff;
  border: 1px solid #ececec;
  font-size: 1rem;
  margin-top: 0.75rem;
  color: #000;
  transition: 0.25s all ease;
}
.Register .Register__container .Register--inner form .floating-label input:focus ~ label,
.Register .Register__container .Register--inner form .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.85rem;
  left: 1rem;
  font-size: 12px;
  transition: 0.25s all ease;
  color: #9a9a9a;
}
.Register .Register__container .Register--inner form .floating-label input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Register .Register__container .Register--inner form .floating-label input:disabled {
  background: #e7e7e7;
}
.Register .Register__container .Register--inner form .floating-label label {
  color: #9a9a9a;
  transition: 0.25s all ease;
  position: absolute;
  left: 1rem;
  top: 2rem;
  pointer-events: none;
}
.Register .Register__container .Register--inner form .floating-label.true input {
  border-color: #d40000;
}
.Register .Register__container .Register--inner form .floating-label.invalid input {
  border-color: #d40000;
}
.Register .Register__container .Register--inner form .inline-label {
  display: inline-flex;
  white-space: nowrap;
}
.Register .Register__container .Register--inner form .inline-label label {
  cursor: pointer;
  user-select: none;
}
.Register .Register__container .Register--inner form .inline-label input[type="checkbox"] {
  position: relative;
  transform: translateX(-4px);
  margin-right: 10px;
  cursor: pointer;
}
.Register .Register__container .Register--inner form .inline-label input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15.5px;
  height: 15px;
  top: 0;
  left: 0;
  border: 1.5px solid #000;
  border-radius: 3px;
  background-color: #fff;
}
.Register .Register__container .Register--inner form .inline-label input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1.5px;
  left: 5.25px;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond {
  display: none;
  align-items: center;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond.active {
  display: block;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label label {
  top: 1.3rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label input:focus ~ label,
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label input:not(:placeholder-shown) ~ label {
  top: 0.3rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .floating-label .error-message {
  top: -11px;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label label {
  top: 2rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label input:focus ~ label,
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label input:not(:placeholder-shown) ~ label {
  top: 1rem;
}
.Register .Register__container .Register--inner .Registration__part--PartSecond .Password .floating-label .error-message {
  top: 0px;
}
.Register .Register__container .Register--inner .next-step {
  background: #00b27e;
  color: #fff;
  border-radius: 4px;
  padding: 1rem;
  border: none;
  display: inline-block;
  width: 200px;
  margin: auto;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 3rem;
  transition: 0.25s all ease;
  font-family: "Inter";
  cursor: pointer;
}
.Register .Register__container .Register--inner .next-step:hover {
  background: #07ce94;
}
.Register .Register__container .Register--inner .Password {
  margin: 3rem 0 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.Register .Register__container .Register--inner .termslinks {
  margin-top: 2rem;
  font-size: 0.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  white-space: normal !important;
}
.Register .Register__container .Register--inner .termslinks a {
  color: #313131;
  text-decoration: underline;
}
.Register .Register__container .Register--inner .termslinks .error-message {
  position: absolute;
  top: -25px;
  right: unset;
}
.Register .Register__container .Register--inner .formSubmit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.Register .Register__container .Register--inner .formSubmit .next-step {
  margin: auto 0;
}
.Register .Register__container .Register--inner .formSubmit .next-step.back {
  background: #fff;
  border: 2px solid #050720;
  color: #050720;
  transition: 0.25s all ease;
  padding: 14px;
  width: 100px;
}
.Register .Register__container .Register--inner .formSubmit .next-step.back:hover {
  background: #050720;
  color: #fff;
}
.Register .Register__container .Register--inner .formSubmit.form-next {
  justify-content: flex-end;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper {
  position: relative;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper button.true {
  color: #00b27e;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper .loader {
  display: none;
  position: absolute;
  top: 10px;
}
.Register .Register__container .Register--inner .formSubmit .Submit__Wrapper .loader.true {
  display: block;
}
.error-message {
  color: #d40000;
  font-size: 0.8rem;
  padding: 0.25rem;
  background: #fff;
  position: absolute;
  top: 0;
  right: 5px;
}
@media (max-width: 1200px) {
  .Register .Register__helper {
    padding-left: 5rem;
  }
}
@media (max-width: 992px) {
  .Register {
    flex-direction: column;
  }
  .Register .Register__helper {
    margin: 0;
    margin-bottom: 3rem;
    height: auto;
  }
  .Register .Register__helper .Register__steps {
    display: none;
  }
  .Register .Register__helper .Register--titles {
    text-align: center;
  }
  .Register .Register__container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .Register .Register__container .Register--inner h3 {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 578px) {
  .Register .Register__helper {
    padding: 2rem;
    margin-bottom: 0;
  }
  .Register .Register__helper .Register--titles .Register--titles--inner h1 {
    font-size: 2rem;
  }
  .Register .Register__helper .Register--titles .Register--titles--inner p {
    font-size: 1rem;
  }
  .Register .Register__helper .Register--titles .Register--titles--inner .mobile-step .step-one:after {
    width: 65px;
  }
  .Register .Register__container .Register--inner {
    max-width: 100%;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .Register .Register__container .Register--inner .formSubmit {
    display: flex;
    flex-direction: column;
  }
  .Register .Register__container .Register--inner .formSubmit .next-step.back {
    margin-bottom: 1rem;
  }
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 16px auto;
  position: relative;
  font-size: 3px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.loader.false {
  display: none;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@supports (-ms-ime-align: auto) {
  .Register .Register__container .Register--inner form .floating-label label {
    position: absolute !important;
    top: 1rem !important;
    font-size: 12px;
  }
  .Register .Register__container .Register--inner form .Registration__part--PartFirst .Register--inner--block .Register--inner--inline .floating-label label {
    top: 0.25rem !important;
  }
  .Register .Register__container .Register--inner form .Registration__part--PartSecond .floating-label label {
    top: 0.25rem !important;
  }
  .Register .Register__container .Register--inner form .Registration__part--PartSecond .Password .floating-label label {
    top: 1rem !important;
  }
}

.Register__thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #f4f8fc;
  h1 {
    font-size: 3rem;
    margin-bottom: 0;
    color: #31347D;
  }
  h2 {
    color: #000;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  a {
    color: #000;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration: none;
  }
}

.terms-error-message {
  color: #d40000;
  font-size: 0.8rem;
}