.Notifications form {
  max-width: 360px;
}
.Notifications form h3 {
  color: #686a7d;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2rem;
}
.Notifications form .toggle-wrapper label {
  display: inline-flex;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  color: #050720;
}
.Notifications form .toggle-wrapper label .toggler {
  width: 50px;
  height: 25px;
  background: #b2b2b2;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.Notifications form .toggle-wrapper label .toggler:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #b2b2b2;
  transition: 0.25s all ease;
}
.Notifications form .toggle-wrapper label input {
  display: none;
}
.Notifications form .toggle-wrapper label input:checked ~ .toggler {
  background: #2bbf8d;
}
.Notifications form .toggle-wrapper label input:checked ~ .toggler:before {
  transform: translateX(100%);
  left: unset;
  transition: 0.25s all ease;
}
.Notifications form .toggle-wrapper label input:hover {
  cursor: pointer;
}
.Notifications form .toggle-wrapper label:hover {
  cursor: pointer;
}
.Notifications .Form .Form--inner form .Form--block h3 {
  margin: 1.5rem 0;
}
@media screen and (min-width: 992px) {
  .Notifications .Form .Form--inner form .Form--block {
    margin: 0 3rem;
  }
  .Notifications .Form .Form--inner form .Form--block h3 {
    justify-content: center;
    align-items: center;
  }
}
