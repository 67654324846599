.Messages_List {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fbfbfc;
  min-height: 100vh;
  overflow-y: auto;
}
.Messages_List .Messages_List--inner {
  padding: 1rem;
  background: #fff;
  box-shadow: 10px 3px 27px 0px rgba(151, 180, 203, 0.16);
}
.Messages_List .Messages_List--inner .Messages_Search {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection {
  width: 100%;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper {
  position: relative;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input {
  width: 100%;
  padding: 0.75rem;
  padding-left: 4rem;
  border: 1px solid #9aabc2;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  color: #292929;
  font-size: 1rem;
  font-weight: 600;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input::placeholder {
  color: #a8a8a8;
  font-weight: 400;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input input:focus {
  transition: 0.25s all ease;
  outline: none;
  box-shadow: 0 0 0 2px #a0d1fa;
}
.Messages_List .Messages_List--inner .Messages_Search .input-collection .input-wrapper .input img {
  user-select: none;
  pointer-events: none;
  position: absolute;
  left: 1rem;
  top: 0.75rem;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select {
  position: relative;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box {
  background: #fff;
  border: 1px solid #9aabc2;
  border-radius: 4px;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  position: relative;
  width: 100%;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box h3 {
  margin: 0;
  color: #686a7d;
  font-weight: 400;
  font-size: 14px;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box img {
  position: absolute;
  right: 1rem;
  top: 1.1rem;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box.show {
  margin-top: -1px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box.show img {
  transform: rotate(180deg);
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Offer_Filter_Select--box.show ~ .Filter_Select_Options {
  transition: 0.25s all ease;
  max-height: 500px;
  opacity: 1;
  overflow: visible;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options {
  z-index: 4;
  position: absolute;
  border: 1px solid #9aabc2;
  border-top: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 41px;
  left: 0;
  background: #fff;
  width: 100%;
  display: flex;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.25s all ease;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options ul li {
  list-style-type: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: #686a7d;
  line-height: 1.6;
  cursor: pointer;
}
.Messages_List .Messages_List--inner .Offer_Filter_Select .Filter_Select_Options ul li:hover {
  background: #66a1f9;
  color: #fff;
}
.Messages_List .Messages_List--inner .Messages {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 50px;
}
.Messages_List .Messages_List--inner .Messages .Messages--header h2 {
  color: #292929;
  font-size: 1.25rem;
  margin: 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select {
  position: relative;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box {
  background: #fff;
  border: 1px solid #9aabc2;
  border-radius: 4px;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  position: relative;
  width: 155px;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box h3 {
  margin: 0;
  color: #686a7d;
  font-weight: 400;
  font-size: 14px;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box img {
  position: absolute;
  right: 1rem;
  top: 1.1rem;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box.show {
  margin-top: -1px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box.show img {
  transform: rotate(180deg);
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select--box.show ~ .Filter_Select_Options {
  transition: 0.25s all ease;
  max-height: 500px;
  opacity: 1;
  overflow: visible;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options {
  z-index: 3;
  position: absolute;
  border: 1px solid #9aabc2;
  border-top: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 41px;
  left: 0;
  background: #fff;
  width: 100%;
  display: flex;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.25s all ease;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options ul li {
  list-style-type: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: #686a7d;
  line-height: 1.6;
}
.Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select .Filter_Select_Options ul li:hover {
  background: #66a1f9;
  color: #fff;
}
.Messages_List .Messages_List--inner .Messages .All_Messages {
  margin: 1rem 0;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper {
  position: relative;
  padding: 0.75rem 0 1rem 1.25rem;
  border-radius: 6px;
  margin: 1rem 0;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h4,
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h5 {
  margin: 0;
  line-height: 1.5;
  width: 100%;
  padding-right: 3.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h4 {
  color: #292930;
  font-weight: 700;
  padding-bottom: 0.5rem;
  padding-top: 0.25rem;
  font-size: 1rem;
  pointer-events: none;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper h5 {
  color: #686868;
  font-weight: 400;
  font-size: 14px;
  pointer-events: none;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper.has-unread h5 {
  pointer-events: none;
  font-weight: 500;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Tender {
  background: #a5c6dc;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Roundtrip {
  background: #a5dcc6;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.OTL {
  background: #f3dcbd;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Tender {
  margin: 0;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .message-color-code.Spotload {
  background: #cca5dc;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .unread-messages-count {
  pointer-events: none;
  position: absolute;
  top: 1rem;
  right: 0.6rem;
  width: 25px;
  height: 25px;
  background: #e13505;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper .last-message-sent-date {
  pointer-events: none;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  font-size: 12px;
  color: #b7b8ba;
}
.Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper.active {
  box-shadow: 10px 3px 27px 0px rgba(151, 180, 203, 0.16);
  background: #fff;
}
.Messages_List.empty-list {
  height: auto;
  min-height: auto;
}
@media screen and (min-width: 1200px) {
  .Messages .Messages--inner .Messages_List {
    width: 320px;
    height: 100vh;
    min-width: 320px;
    border: 1px solid #e7e8eb;
    padding: 1rem;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner {
    padding: 0;
    background: none;
    box-shadow: none;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .Messages--header {
    background: none;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .Messages--header h2 {
    overflow: hidden;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .Messages--header .Filter_Select:hover {
    cursor: pointer;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper:hover {
    cursor: pointer;
    background: #f5f5f5;
  }
  .Messages .Messages--inner .Messages_List .Messages_List--inner .Messages .All_Messages .message-wrapper.active:hover {
    background: #fff;
  }
  .Messages .Messages--inner .Messages_List::-webkit-scrollbar-track {
    background-color: #fbfbfc;
  }
  .Messages .Messages--inner .Messages_List::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  .Messages .Messages--inner .Messages_List::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
}
@media screen and (min-width: 1500px) {
  .Messages .Messages--inner .Messages_List {
    width: 360px;
    min-width: 360px;
  }
}
.Messages .Welcome_Mat {
  width: 100%;
  padding: 0;
}
.Messages .Welcome_Mat .Welcome_Mat--inner {
  height: 100%;
}
@media (max-width: 992px) {
  .Messages {
    margin-top: 64px;
  }
}
