.Mini_Messages {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  width: auto;
  z-index: 1001;
  margin-right: 1rem;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message {
  pointer-events: all;
  width: 300px;
  height: 360px;
  margin-left: 1rem;
  background: #fff;
  border: 1px solid #eeeeee;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header {
  align-items: center;
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
  height: 50px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header-minimize {
  background: #f9f9f9;
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 245px;
  height: 50px;
  min-width: 245px;
  display: flex;
  border-top-left-radius: 6px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header-minimize:hover {
  cursor: pointer;
  background: #eaeaea;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--image {
  width: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--image img {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: contain;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent {
  width: 204px;
  min-width: 204px;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h3,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h4 {
  display: block;
  padding: 0 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  margin: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h3 {
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--recipent h4 {
  color: #686a7d;
  font-size: 12px;
  font-weight: 500;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 54px;
  min-width: 54px;
  max-width: 54px;
  padding-right: 5px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options {
  width: 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options img {
  width: 100%;
  height: 17.5px;
  pointer-events: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 91;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul {
  margin: 0;
  padding: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li {
  white-space: nowrap;
  list-style-type: none;
  display: flex;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a {
  font-size: 14px;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  color: #191919;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a:active,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a:focus {
  color: #191919;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options .options-dropdown ul li a:hover {
  background: #f9f9f9;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .options:hover {
  cursor: pointer;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .close {
  width: 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .close img {
  width: 100%;
  height: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Mini_Message--header .Message--header--actions .close:hover {
  cursor: pointer;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box {
  height: 310px;
  position: relative;
  background: #fff;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner {
  padding: 0 0.5rem 0.5rem 0.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  transition: 0.25s height ease;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message {
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] {
  margin-top: 2.5rem;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .sender {
  border-top-right-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] .reciever {
  border-top-left-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .sender {
  border-bottom-right-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-end] .reciever {
  border-bottom-left-radius: 15px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .reciever-avatar {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  object-fit: contain;
  position: absolute;
  left: -12px;
  bottom: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .seen {
  position: absolute;
  right: -17.5px;
  bottom: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever {
  padding: 0.5rem 0.75rem;
  display: flex;
  display: block;
  flex-wrap: wrap;
  max-width: max-content;
  border-radius: 15px;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h1,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h1,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h2,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h2,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h3,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h3,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h4,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h4,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender h5,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever h5,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender p,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever p,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender span,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever span,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender li,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever li,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender a,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever a {
  margin: 0;
  line-height: 1.4;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender p,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever p {
  width: 100%;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender a,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever a {
  color: #66a1f9;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender strong,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever strong {
  font-weight: 600;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender:hover:before,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever:hover:before {
  content: attr(data-created);
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.1rem 0.25rem;
  border-radius: 3px;
  line-height: 1.5;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  font-size: 12px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin: 2px 0 2px auto;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .reciever {
  background: #eef9f5;
  margin-right: auto;
  position: relative;
  margin: 2px auto 2px 1.25rem;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message {
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message[data-start] {
  margin-top: 2.5rem;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .reciever-avatar {
  margin-left: 2px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sent-message .seen {
  position: absolute;
  right: -17.5px;
  bottom: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender {
  background: #d5f1fe;
  justify-content: flex-start;
  margin-left: auto;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner .sender .sender-seen {
  position: absolute;
  bottom: -0.5rem;
  right: -1rem;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-track {
  background-color: #fff;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar {
  background-color: #eaeaea;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Current_Message_Box--inner::-webkit-scrollbar-thumb {
  background-color: #c5c6d3;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  min-height: 70px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message {
  min-height: 60px;
  border-top: 1px solid #eee;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner {
  display: inline-flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
  position: relative;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box {
  display: inline-flex;
  width: 100%;
  max-width: 260px;
  min-height: 60px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
  border: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .second-toolbar,
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-newline {
  display: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-btn-grp {
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-btn-grp button {
  width: 24px;
  height: 27px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-btn-grp button .fr-svg {
  margin: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-toolbar .fr-popup {
  top: unset !important;
  left: 0px !important;
  bottom: 60px !important;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper {
  width: 100%;
  max-width: 235px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element {
  max-height: 164px;
  overflow-y: auto;
  padding-left: 0.5rem !important;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element::-webkit-scrollbar-track {
  background-color: #fff;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element::-webkit-scrollbar {
  background-color: #eaeaea;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .fr-box .fr-wrapper .fr-element::-webkit-scrollbar-thumb {
  background-color: #c5c6d3;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  min-height: 40px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .Send_Icon {
  width: 40px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  position: absolute;
  top: 0;
  right: 0;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .Send_Icon img {
  width: 30px;
  height: 30px;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box .Send_Message .Send_Message--inner .Send_Icon:hover {
  cursor: pointer;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message .Current_Message_Box.minimize {
  height: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  display: none;
}
.Mini_Messages .Mini_Messages--inner .Mini_Message.minimize {
  height: 50px;
}
.Mini_Messages.hide {
  display: none;
}
@media screen and (min-width: 992px) {
  .Mini_Messages {
    display: block;
  }
  .Mini_Messages.pos-adjusted {
    margin-right: calc(400px + 1rem);
  }
}
.fr-tooltip {
  display: none;
}
