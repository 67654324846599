.Form .Form--inner form {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.Form .Form--inner form h5 {
  color: #424242;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
}
.Form .Form--inner form .input-collection {
  margin: 1rem 0;
}
.Form .Form--inner form .input-collection h4 {
  color: #424242;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
}
.Form .Form--inner form .input-collection .input-wrapper .input {
  display: flex;
  width: 100%;
  position: relative;
}
.Form .Form--inner form .input-collection .input-wrapper .input input,
.Form .Form--inner form .input-collection .input-wrapper .input textarea {
  width: 100%;
  border: none;
  padding: 0.5rem 0.25rem;
  font-family: "Inter", sans-serif;
  color: #050720;
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid #9aabc2;
  font-size: 1rem;
  resize: none;
  height: 42.5px;
  line-height: 1.6;
  margin-bottom: -2px;
}
.Form .Form--inner form .input-collection .input-wrapper .input input::placeholder,
.Form .Form--inner form .input-collection .input-wrapper .input textarea::placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:focus,
.Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
  outline: none;
  border-bottom: 2px solid #277dff;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:focus + .input:before,
.Form .Form--inner form .input-collection .input-wrapper .input textarea:focus + .input:before {
  height: 2px;
}
.Form .Form--inner form .input-collection .input-wrapper .input input:disabled,
.Form .Form--inner form .input-collection .input-wrapper .input textarea:disabled {
  background: #eaeaea;
}
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"],
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"] {
  position: relative;
}
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-inner-spin-button,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-inner-spin-button,
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-outer-spin-button,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-outer-spin-button {
  padding: 1rem 0.5rem;
}
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-inner-spin-button:hover,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-inner-spin-button:hover,
.Form .Form--inner form .input-collection .input-wrapper .input input[type="number"]::-webkit-outer-spin-button:hover,
.Form .Form--inner form .input-collection .input-wrapper .input textarea[type="number"]::-webkit-outer-spin-button:hover {
  cursor: pointer;
}
.Form .Form--inner form .input-collection .input-wrapper .input input.invalid,
.Form .Form--inner form .input-collection .input-wrapper .input textarea.invalid {
  border-color: #e13505;
}
.Form .Form--inner form .input-collection .input-wrapper .input textarea {
  transition: 0.25s all ease;
}
.Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
  transition: 0.25s all ease;
  height: 150px;
}
.Form .Form--inner form .input-collection .input-wrapper .input label {
  width: 100%;
  display: inline-flex;
  align-items: center;
  color: #050720;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input {
  width: 15px;
  height: 15px;
  margin: 0;
  margin-right: 1rem;
  position: relative;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input[type="radio"]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #9aabc2;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input[type="radio"]:checked:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4.5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #262a68;
}
.Form .Form--inner form .input-collection .input-wrapper .input label input:hover {
  cursor: pointer;
}
.Form .Form--inner form .input-collection .input-wrapper .input label:focus {
  outline: none;
}
.Form .Form--inner form .input-collection .input-wrapper .input label:hover {
  cursor: pointer;
}
.Form .Form--inner form .input-collection .input-wrapper .input .error {
  position: absolute;
  top: -12.5px;
  right: 15px;
  background: #fff;
  padding: 0.25rem;
  user-select: none;
}
.Form .Form--inner form .input-collection .input-wrapper .input .error p {
  color: #e13505;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control {
  padding: 0.5rem 0.25rem;
  border: none;
  border-bottom: 1px solid #9aabc2;
  outline: none;
  box-shadow: none;
  border-radius: none;
  font-weight: 600;
  border-radius: 0;
  margin-bottom: -2px;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control.react-select__control--is-focused {
  border-bottom: 2px solid #277dff;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__value-container {
  margin-bottom: -2px;
  padding: 0;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__single-value {
  margin: 0;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__indicator {
  padding: 0 8px;
}
.Form .Form--inner form .input-collection .input-wrapper .react-select__control .react-select__placeholder {
  color: #b0b0b5;
  font-size: 14px;
  font-weight: 400;
}
.Form .Form--inner form .inline-input {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
}
.Form .Form--inner form .inline-input .input-collection {
  margin-right: 2rem;
}
.Form .Form--inner form .textarea-counter {
  margin-bottom: 1.5rem;
  position: relative;
}
.Form .Form--inner form .textarea-counter p {
  position: absolute;
  right: 0;
  bottom: -17.5px;
  margin: 0;
  font-size: 11px;
  user-select: none;
}
.Form .Form--inner form .dropzone {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.Form .Form--inner form .dropzone:focus {
  outline: none;
}
.Form .Form--inner form .dropzone p {
  margin: 0;
  color: #686a7d;
  font-size: 14px;
  margin-right: 1rem;
}
.Form .Form--inner form .dropzone:hover {
  cursor: pointer;
}
.Form .Form--inner form .dropdown {
  width: 100%;
}
.Form .Form--inner form .dropdown.invalid .react-select__control {
  border-color: #e13505;
}
.Form .Form--inner form .avatar-preview img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}
.Form .Form--inner form .Form--block {
  margin: 2rem 0;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 30px 0px rgba(151, 180, 203, 0.16);
  padding: 1rem;
}
.Form .Form--inner form .Form--block h3 {
  font-size: 14px;
  color: #277dff;
  font-weight: 500;
  margin: 0;
  margin-bottom: 2rem;
}
.Form .Form--inner form .Form--block .error p {
  color: #e13505;
  font-size: 14px;
}
.Form .Form--inner form input[type="submit"] {
  background: none;
  border: 2px solid #262a68;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #262a68;
  font-weight: 700;
  padding: 1.25rem 2.75rem;
  font-size: 1rem;
  transition: 0.25s all ease;
  margin: 1.5rem 0;
  background: #fff;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Form .Form--inner form input[type="submit"]:focus,
.Form .Form--inner form input[type="submit"]:hover {
  transition: 0.25s all ease;
  outline: none;
  background: #262a68;
  color: #fff;
}
.Form .Form--inner form input[type="submit"]:hover {
  cursor: pointer;
}
.Form .Form--inner form input[type="submit"].success {
  border: 2px solid #2bbf8d;
  color: #2bbf8d;
}
.Form .Form--inner form input[type="submit"].success:hover {
  background: #2bbf8d;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .Form .Form--inner {
    background: #fff;
    border-radius: 7px;
    padding: 2rem 0;
  }
  .Form .Form--inner form .Form--block {
    box-shadow: none;
    border: none;
    padding: 0;
  }
  .Form .Form--inner form .Form--block h3 {
    display: flex;
    margin: 2rem 0;
    margin-left: 35%;
  }
  .Form .Form--inner form .input-collection {
    margin: 1.5rem 0;
  }
  .Form .Form--inner form .input-collection .input-wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .Form .Form--inner form .input-collection .input-wrapper h4 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    width: 50%;
    text-align: right;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input input,
  .Form .Form--inner form .input-collection .input-wrapper .input textarea {
    border: 1px solid #9aabc2;
    border-radius: 6px;
    padding: 0.5rem;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input input:focus,
  .Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
    transition: 0.25s all ease;
    outline: none;
    box-shadow: 0 0 0 2px #a0d1fa;
    border-bottom: 1px solid #9aabc2;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea {
    overflow-y: hidden;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea:focus {
    overflow-y: auto;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-scrollbar-track {
    background-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-scrollbar {
    background-color: #fff;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  .Form .Form--inner form .input-collection .input-wrapper .input textarea ~ .error p {
    background: #fff;
    padding: 0.25rem;
  }
  .Form .Form--inner form .input-collection .input-wrapper .react-select__control {
    border: 1px solid #9aabc2;
    border-radius: 6px;
    padding: 0.5rem;
  }
  .Form .Form--inner form .input-collection .input-wrapper .react-select__control.react-select__control--is-focused {
    transition: 0.25s all ease;
    outline: none;
    box-shadow: 0 0 0 2px #a0d1fa;
    border-bottom: 1px solid #9aabc2;
  }
  .Form .Form--inner form .inline-input {
    flex-direction: row;
  }
  .Form .Form--inner form .inline-input h5 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    align-items: center;
    width: 50%;
    text-align: right;
  }
  .Form .Form--inner form input[type="submit"] {
    max-width: 50%;
    margin: 3rem auto;
  }
  .Form .Form--inner form input[type="submit"]:focus {
    transition: 0.25s all ease;
    outline: none;
    box-shadow: 0 0 0 2px #a0d1fa;
  }
}
