.Card {
  border: 1px solid #eeeeee;
  border-radius: 7px;
  padding: 1.5rem;
  background: #fff;
  width: 100%;
  margin-top: 1rem;
  position: unset;
}
.Card .Card-header {
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
}
.Card .Card-header h3 {
  margin: 0;
  color: #262a68;
  font-size: 1.25rem;
  padding-right: 1.5rem;
}
.Card .Card-header img {
  margin-right: 1rem;
}
.Card .Card-header a {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #262a68;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #262a68;
  border-radius: 4px;
  margin-left: auto;
  top: 1.25rem;
  right: 1rem;
  transition: 0.25s all ease;
}
.Card .Card-header a:hover {
  background: #262a68;
  color: #fff;
  transition: 0.25s all ease;
}
.Card .Card-content .all-button,
.Card .Card-content .add-button {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
}
.Card .Card-content .all-button a,
.Card .Card-content .add-button a {
  text-decoration: none;
  font-weight: 500;
}
.Card .Card-content .add-button a {
  color: #0184e8;
  border: 1px solid #0184e8;
  border-radius: 50px;
  padding: 1rem 2.75rem;
  transition: 0.25s all ease;
  text-decoration: none;
}
.Card .Card-content .add-button a:hover {
  transition: 0.25s all ease;
  background: #0184e8;
  color: #fff;
}
.Card .Card-content .all-button a {
  color: #292930;
  border-bottom: 1px solid #292930;
  font-size: 1.1rem;
}
.Card .Card-content .Latest {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
}
.Card .Card-content .Latest a {
  position: relative;
  text-decoration: none;
}
.Card .Card-content .Latest h5 {
  color: #050720;
  font-size: 14px;
  font-weight: 500;
  margin: 1rem 0;
  line-height: 1.5;
}
@media (max-width: 992px) {
  .Card .Card-content .Latest a:before {
    content: "";
    position: absolute;
    left: -1rem;
    top: 1.5rem;
    width: 6px;
    height: 6px;
    border-radius: 99px;
    background: #e13505;
  }
}
.Card .Table {
  padding: 0;
}
.Card .Table .Table--inner .Table-content .Table-row {
  padding: 0;
  box-shadow: none;
  border: none;
  margin: 0;
}
.Card .Table .Table--inner .Table-content .Table-row .item:first-child {
  padding-top: 0.75rem;
}
@media screen and (min-width: 800px) {
  .Card-inline {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .Card-inline .Card {
    width: calc(50% - 2rem);
  }
  .Card-inline .Card:nth-child(odd) {
    margin-right: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .Card .Card-header {
    display: inline-flex;
  }
  .Card .Card-header h3 {
    font-size: 1.5rem;
  }
  .Card .Card-header a {
    padding: 0.5rem 2rem;
  }
  .Card .Table .Table--inner .Table-content .Table-row .item:first-child {
    padding-top: 0;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .Card-inline {
    display: flex;
  }
  .Card-inline .Card {
    width: 100%;
  }
  .Card-inline .Card:nth-child(odd) {
    margin-right: 0rem;
  }
}
.Card.landing-blue {
  border: 1px solid #262a68;
  border-radius: 7px;
  padding: 2rem;
  background: #262a68;
  width: 100%;
  height: fit-content;
  margin-top: 1rem;
  position: unset;
}
.Card.landing-blue .Card-header {
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
}
.Card.landing-blue .Card-header h3 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  padding-right: 1.5rem;
}
.Card.landing-blue .Card-content {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
  line-height: 1.5;
}
.Card.landing-white {
  border: 1px solid #eeeeee;
  border-radius: 7px;
  padding: 1.75rem;
  background: #fff;
  width: 285px;
  height: 266px;
  margin-top: 1rem;
  position: unset;
}
.Card.testimonials {
  height: fit-content;
}
.Card.testimonials .Card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Card.testimonials .Card-content p {
  margin: unset;
}