@import url("https://rsms.me/inter/inter.css");
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
body,
html {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.navbar-desktop {
  position: relative;
  z-index: 11;
}
.navbar-desktop header {
  display: inline-flex;
  width: 100%;
  height: 130px;
  gap: 2rem;
}
.navbar-desktop header a.logo {
  display: flex;
  width: 240px;
}
.navbar-desktop header .nav__items {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.navbar-desktop header .nav__items a {
  color: #0f1540;
  padding: 0.75rem 0.3rem;
  /* margin: 0 0.3rem; */
  text-decoration: none;
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
}
/* .navbar-desktop header .nav__items a:hover:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.4rem;
  background: #e13505;
  width: 100%;
  height: 3px;
} */
.navbar-desktop header .info {
  position: relative;
}
.navbar-desktop header .info:hover > a {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 7px;
    text-decoration-color: #0f1540;
}
.navbar-desktop header .info:hover .info--dropdown {
  display: block;
}
.navbar-desktop header .info--dropdown {
  display: none;
  position: absolute;
  top: 150%;
  right: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.navbar-desktop header .info--dropdown ul {
  margin: 0;
  padding: 0;
}
.navbar-desktop header .info--dropdown ul li {
  list-style: none;
  transition: 0.15s all ease;
  color: #191919;
}
.navbar-desktop header .info--dropdown ul li a {
  display: inline-block;
  padding: 0.7rem;
  padding-right: 2rem;
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0;
  background: none;
  color: #191919;
  text-transform: none;
  width: 100%;
  border-radius: 0;
  transition: 0.15s all ease;
}
.navbar-desktop header .info--dropdown ul li a:hover {
  transition: 0.15s all ease;
  background: #f9f9f9;
  text-decoration: none;
}
.navbar-desktop header .info--dropdown ul li a:hover:before {
  display: none;
}
.navbar-desktop header .info--dropdown ul li:hover {
  cursor: pointer;
}
.navbar-desktop header .nav__actions {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.navbar-desktop header .nav__actions :is(.nav__logged_actions) a {
  text-decoration: none;
  font-weight: 700;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  margin: 0 0.25rem;
  font-size: 0.85rem;
  text-transform: uppercase;
}
.navbar-desktop header .nav__actions .nav__logged_out_actions a#log_in {
  font-weight: 600;
  font-size: 1rem;
  color: #0E114A;
  text-decoration: none;
}
.navbar-desktop header .nav__actions .nav__logged_out_actions a#log_in:hover {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 7px;
  text-decoration-color: #DD5626;
}
.navbar-desktop header .nav__actions .nav__logged_out_actions a:last-child {
  color: #fff;

  transition: 0.25s all ease;
}
.navbar-desktop header .nav__actions .nav__logged_actions {
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
}
.navbar-desktop header .nav__actions .nav__logged_out_actions {
  position: relative;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
.navbar-desktop header .nav__actions .nav__logged_actions img {
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
}
.navbar-desktop header .nav__actions .nav__logged_actions a {
  border: none;
  padding: 0;
  background: none;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email {
  position: relative;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email .email--count {
  position: absolute;
  right: 0;
  bottom: 0;
}

.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .email
  .email--count
  h6 {
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #e13505;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.7rem;
}
.navbar-desktop header .nav__actions .nav__logged_actions .email,
.navbar-desktop header .nav__actions .nav__logged_actions .user {
  margin: 0 0.25rem;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user {
  width: 100%;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user:hover
  .user--dropdown {
  display: block;
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
}
.navbar-desktop header .nav__actions .nav__logged_actions .user--dropdown ul {
  margin: 0;
  padding: 0;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user--dropdown
  ul
  li {
  list-style: none;
  transition: 0.15s all ease;
  color: #191919;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user--dropdown
  ul
  li
  a {
  display: inline-block;
  padding: 0.7rem;
  padding-right: 2rem;
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0;
  background: none;
  color: #191919;
  text-transform: none;
  width: 100%;
  border-radius: 0;
  transition: 0.15s all ease;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user--dropdown
  ul
  li
  a:hover {
  transition: 0.15s all ease;
  background: #f9f9f9;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user--dropdown
  ul
  li:last-child {
  border-top: 1px solid #e8e8e8;
  padding: 0.7rem;
  font-size: 0.85rem;
  font-weight: 500;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user--dropdown
  ul
  li:last-child:hover {
  transition: 0.15s all ease;
  background: #f9f9f9;
}
.navbar-desktop
  header
  .nav__actions
  .nav__logged_actions
  .user--dropdown
  ul
  li:hover {
  cursor: pointer;
}
.navbar-mobile {
  background: #f9f9f9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}
.navbar-mobile header {
  display: inline-flex;
  width: 100%;
  height: 60px;
  position: relative;
}
.navbar-mobile header a.logo {
  display: flex;
}
.navbar-mobile header a.logo img {
  width: 120px;
}
.navbar-mobile header .burger {
  width: 35px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 1.25rem;
}
.navbar-mobile header .burger .burger-inner {
  background: #262a68;
  width: 100%;
  height: 2.5px;
  position: relative;
}
.navbar-mobile header .burger .burger-inner:before,
.navbar-mobile header .burger .burger-inner:after {
  content: "";
  position: absolute;
  background: #262a68;
  width: 100%;
  height: 2.5px;
}
.navbar-mobile header .burger .burger-inner:before {
  top: 10px;
}
.navbar-mobile header .burger .burger-inner:after {
  top: 20px;
}
.navbar-mobile header .nav__items {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5rem 4rem 2rem 3rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
  gap: 1rem;
}

.navbar-mobile header .nav__items .info--dropdown li {
  padding: 0.75rem 0;
  list-style-type: none;
}

.navbar-mobile header .nav__items .info .link div {
  display: flex;
  justify-content: space-between;
}

.navbar-mobile header .nav__items.off {
  transition: 0.25s all ease;
  opacity: 0;
  visibility: hidden;
}
.navbar-mobile header .nav__items.on {
  transition: 0.25s all ease;
  opacity: 1;
  visibility: visible;
}
.navbar-mobile header .nav__items a {
  text-decoration: none;
  color: #0f1540;
  font-weight: 600;
  padding: 0.5rem 0;
}
.navbar-mobile header .nav__items .nav__actions {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}
.navbar-mobile header .nav__items .nav__actions a {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0.75rem 0;
  border-radius: 2rem;
  font-weight: 700;
}
.navbar-mobile header .nav__items .nav__actions a:first-child {
  background: #00b27e;
  border: 2px solid #00b27e;
  color: #fff;
}
.navbar-mobile header .nav__items .nav__actions a:last-child {
  border: 2px solid #262a68;
  color: #0f1540;
}
.navbar-mobile header .nav__items div .nav__logged_actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border-top: 1px solid #e8e8e8; */
  padding-top: 0.75rem;
  margin-top: 0.25rem;
  gap: 1rem;
}
.navbar-mobile header .nav__items .nav__actions .nav__logged_actions a {
  background: none;
  border: none;
  color: #0f1540;
  margin: 0;
  padding: 0.75rem;
  display: inline-block;
  width: 100%;
  text-align: left;
  padding-left: 0;
  text-transform: none;
  font-size: 1rem;
  font-weight: 600;
}
.navbar-mobile header .nav__items div .nav__logged_out_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-top: 1px solid #e8e8e8; */
  padding-top: 0.75rem;
  margin-top: 0.25rem;
  gap: 1rem;
}
.navbar-mobile header .nav__items div .nav__logged_out_actions #sign-up-btn-mbl {
  max-width: -webkit-fill-available;
  width: 100%;
}
.navbar-mobile header .nav__items div .nav__logged_out_actions #sign-up-btn-mbl a {
  color: white;
  padding: 0;
}
.navbar-mobile header .nav__items .close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.navbar-mobile header .nav__items .close img {
  width: 20px;
  height: 20px;
}
@media (max-width: 992px) {
  .navbar-mobile {
    display: block;
  }
  .navbar-desktop {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-desktop {
    display: block;
  }
  .navbar-mobile {
    display: none;
  }
}
@media (max-width: 1140px) and (min-width: 992px) {
  .navbar-desktop header .nav__items a {
    font-size: 14px;
  }
}
