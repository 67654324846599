.Left_Drawer {
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  transition: 0.25s all ease;
  scrollbar-width: none;
}
.Left_Drawer::-webkit-scrollbar {
  display: none;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav {
  padding: 1rem;
  box-shadow: 0px -1px 5px 0px rgba(151, 180, 203, 0.16);
  background: #fff;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul {
  margin: 0;
  padding: 0;
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  align-items: center;
  color: #686a7d;
  line-height: 1.5;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul li a {
  color: #686a7d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
}
.Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav ul li svg {
  width: 20px;
  height: 20px;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items {
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 3rem;
  z-index: 11;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul {
  margin: 1rem 0;
  padding: 0;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul hr {
  border: none;
  height: 1px;
  background: #dcdcdc;
  margin: 12px 15px;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li {
  list-style: none;
  position: relative;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a {
  margin: 0 0 0 2.5rem;
  padding: 0.75rem 0;
  padding-left: 0.5rem;
  color: #0f1540;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  position: relative;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a svg {
  width: 20px;
  height: 20px;
  margin-right: 2rem;
  fill: currentColor;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a span {
  background: #e13505;
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 2px;
  padding: 2px;
  border-radius: 50%;
  left: 22px;
  min-width: 16px;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a .company-letters {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #e13505;
  border-radius: 50%;
  margin-right: 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active {
  background: #66a1f9;
  color: #fff;
  font-weight: 600;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active svg path,
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active svg circle,
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a.active svg line {
  stroke: #fff;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li a div div {
  display: flex;
  align-items: center;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items ul li .link-tooltip {
  display: none;
}
.Left_Drawer .Left_Drawer--inner .Drawer--items.true {
  transform: translateY(0);
}
.Left_Drawer .Left_Drawer--inner .Drawer--logo,
.Left_Drawer .Left_Drawer--inner .Drawer--company {
  display: none;
}
.Left_Drawer-close {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0.5;
  display: none;
  z-index: 11;
}
.Left_Drawer-close.true {
  display: block;
}
@media screen and (min-width: 992px) {
  .Left_Drawer {
    left: 0;
    top: 0;
    height: 100vh;
    width: 84px;
    background: #484f6b;
  }
  .Left_Drawer .Left_Drawer--inner {
    height: inherit;
    padding: 3rem 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo {
    margin-bottom: 2.5rem;
    padding: 0 2.25rem;
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img {
    height: 30px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:first-child {
    width: 111px;
    min-width: 111px;
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:last-child {
    width: 12px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company {
    margin-bottom: 1rem;
    padding: 0 1.5rem;
    overflow: hidden;
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a {
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    color: #484f6b;
    white-space: nowrap;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a .company-letters {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    min-width: 50px;
    height: 40px;
    background: #e13505;
    border-radius: 50%;
    margin-right: 0.75rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items {
    position: relative;
    transform: unset;
    height: unset;
    border-radius: 0;
    background: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul {
    margin: 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    overflow: visible;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a {
    overflow: hidden;
    padding: 0.75rem 2rem;
    color: #c7c7db;
    width: 100%;
    position: relative;
    margin: 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a span {
    left: 45px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: #fff;
    transition: 0.25s opacity ease;
    opacity: 0;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover {
    color: #fff;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover:before {
    transition: 0.25s opacity ease;
    opacity: 1;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover svg path,
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover svg circle,
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li a:hover svg line {
    stroke: #fff;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Left_Drawer .Left_Drawer--inner .Drawer--items {
    overflow: visible;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items ul li {
    position: relative;
    overflow: visible;
  }
  .Left_Drawer
    .Left_Drawer--inner
    .Drawer--items
    ul
    li
    a:hover
    ~ .link-tooltip {
    display: block;
  }
}
.link-tooltip {
  position: absolute;
  padding: 0.5rem;
  pointer-events: none;
  color: #fff;
  z-index: 1;
  font-size: 13px;
  left: 4.5rem;
  white-space: nowrap;
  user-select: none;
}
.link-tooltip:before {
  content: "";
  position: absolute;
  left: -7px;
  top: 8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 7.5px 7.5px 0;
  border-color: transparent #3b3b3b transparent transparent;
  opacity: 0.9;
  border-radius: 3px;
}
.link-tooltip:after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3b3b3b;
  opacity: 0.9;
  border-radius: 3px;
}
@media screen and (min-width: 992px) {
  .Left_Drawer {
    width: 270px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items .only-mobile {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Left_Drawer--mobile-nav {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:first-child {
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:last-child {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a {
    color: #fff;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a .company-letters {
    width: 40px;
    min-width: 40px;
    margin-right: 1.2rem;
  }
  #left-drawer-small {
    width: 84px;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--logo img:first-child {
    display: none;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--logo img:last-child {
    width: 12px;
    display: block;
  }
  #left-drawer-small .Left_Drawer--inner .Drawer--items {
    overflow: visible;
  }
  #left-drawer-small
    .Left_Drawer--inner
    .Drawer--items
    ul
    li
    a:hover
    ~ .link-tooltip {
    display: block;
  }
}
.Right_Drawer {
  background: #fff;
  overflow-y: auto;
  display: none !important;
  z-index: 11;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon {
  margin-right: 0.75rem;
}
.Right_Drawer
  .Right_Drawer--inner
  .Drawer--title
  .notification-icon
  .notifications-blob:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #e13505;
  right: -0.3rem;
  bottom: 0.1rem;
  border-radius: 50%;
}
.Right_Drawer .Right_Drawer--inner .Drawer--title h3 {
  font-size: 18px;
  color: #050720;
  font-weight: 700;
  margin: 0;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #707070;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--head {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--head h4 {
  margin: 0;
  color: #989898;
  font-size: 12px;
  font-weight: 500;
}
.Right_Drawer
  .Right_Drawer--inner
  .Drawer--items
  .Drawer--item
  .item--head
  svg:hover {
  cursor: pointer;
}
.Right_Drawer
  .Right_Drawer--inner
  .Drawer--items
  .Drawer--item
  .item--head
  svg:hover
  path {
  stroke: #000;
}
.Right_Drawer .Right_Drawer--inner .Drawer--items .Drawer--item .item--content {
  margin-top: 0.75rem;
}
.Right_Drawer
  .Right_Drawer--inner
  .Drawer--items
  .Drawer--item
  .item--content
  p {
  margin: 0;
  margin-bottom: 1rem;
  color: #3b3b3b;
  line-height: 1.4;
  font-size: 14px;
}
.Right_Drawer
  .Right_Drawer--inner
  .Drawer--items
  .Drawer--item
  .item--content
  a {
  text-decoration: none;
  color: #3990b7;
  font-size: 14px;
}
.Right_Drawer .Right_Drawer--inner .Right_Drawer--close {
  display: none;
}
@media screen and (min-width: 992px) {
  .Right_Drawer {
    transition: 0.25s all ease;
    height: 100vh;
    width: 270px;
    display: block;
  }
  .Right_Drawer .Right_Drawer--inner {
    padding: 6rem 2rem 0 2rem;
  }
  #right-drawer-small {
    transform: translateX(105%);
    position: fixed;
    right: 0;
    overflow: visible;
    z-index: 11;
  }
  #right-drawer-small .Right_Drawer--inner {
    padding: 6rem 2rem 0 2rem;
    position: relative;
  }
  #right-drawer-small .Right_Drawer--inner .Drawer--title .notification-icon {
    transform: translateX(-159%);
    position: fixed;
    margin: 0;
    padding: 1rem 2rem 1em 1rem;
    display: flex;
    align-items: center;
  }
  #right-drawer-small
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
    z-index: -1;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  #right-drawer-small
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon:hover {
    cursor: pointer;
  }
  #right-drawer-small
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon
    .notifications-blob:before {
    right: 1.7rem;
    bottom: 1.05rem;
  }
  #right-drawer-small .Right_Drawer--inner .Right_Drawer--close {
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #right-drawer-small .Right_Drawer--inner .Right_Drawer--close:hover {
    cursor: pointer;
  }
  #right-drawer-small.true {
    transform: translateX(0);
  }
  #right-drawer-small.true
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon {
    position: relative;
    transform: none;
    padding: 0;
    margin-right: 0.75rem;
  }
  #right-drawer-small.true
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon:before {
    content: unset;
  }
  #right-drawer-small.true
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon
    .notifications-blob:before {
    right: -0.3rem;
    bottom: 0.1rem;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Left_Drawer {
    width: 84px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:first-child {
    display: none;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--logo img:last-child {
    width: 12px;
    display: block;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--company a .company-letters {
    width: 40px;
    min-width: 40px;
  }
  .Left_Drawer .Left_Drawer--inner .Drawer--items {
    overflow: visible;
  }
  .Left_Drawer
    .Left_Drawer--inner
    .Drawer--items
    ul
    li
    a:hover
    ~ .link-tooltip {
    display: block;
  }
  .Right_Drawer {
    transform: translateX(105%);
    position: fixed;
    right: 0;
    overflow: visible;
    z-index: 11;
  }
  .Right_Drawer .Right_Drawer--inner {
    padding: 6rem 2rem 0 2rem;
    position: relative;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon {
    transform: translateX(-159%);
    position: fixed;
    margin: 0;
    padding: 1rem 2rem 1em 1rem;
    display: flex;
    align-items: center;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
    z-index: -1;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .Right_Drawer .Right_Drawer--inner .Drawer--title .notification-icon:hover {
    cursor: pointer;
  }
  .Right_Drawer
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon
    .notifications-blob:before {
    right: 1.7rem;
    bottom: 1.05rem;
  }
  .Right_Drawer .Right_Drawer--inner .Right_Drawer--close {
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Right_Drawer .Right_Drawer--inner .Right_Drawer--close:hover {
    cursor: pointer;
  }
  .Right_Drawer.true {
    transform: translateX(0);
  }
  .Right_Drawer.true .Right_Drawer--inner .Drawer--title .notification-icon {
    position: relative;
    transform: none;
    padding: 0;
    margin-right: 0.75rem;
  }
  .Right_Drawer.true
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon:before {
    content: unset;
  }
  .Right_Drawer.true
    .Right_Drawer--inner
    .Drawer--title
    .notification-icon
    .notifications-blob:before {
    right: -0.3rem;
    bottom: 0.1rem;
  }
}
@media screen and (min-width: 1024px) {
  .Left_Drawer-close {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .Right_Drawer {
    transform: translateX(0);
  }
  .Right_Drawer .Right_Drawer--inner .notification-icon {
    transform: translateX(0);
  }
}
